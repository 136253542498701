import React, { useEffect, useState, useRef } from "react";
import copy from '../copy.svg';
import Footer from "../../../universal/footer";
import '../../../universal/payment.css';
import '../../../universal/buttons.css';
import useDelayedHeightCalculation from "../../../universal/useDelayedHeightCalculation";
import Timer from "../../../universal/timer";

const Payment2 = ({ navbarHeight }) => {
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState('lifetime'); // Default is lifetime
    const [priceInUSDT, setPriceInUSDT] = useState(119);
    const [oldPrice, setOldPrice] = useState(499); // Old prices based on the plan
    const [selectedCrypto, setSelectedCrypto] = useState('USDT'); // Default is USDT
    const [exchangeRates, setExchangeRates] = useState({ sol: 0, xrp: 0, btc: 0 }); // Added BTC
    const [xrpTag, setXrpTag] = useState('10047185'); // Tag/memo for XRP payments

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

    // Fetch exchange rates from CoinGecko API
    const fetchExchangeRates = async () => {
        try {
            const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana,ripple,bitcoin&vs_currencies=usd');
            const data = await response.json();
            setExchangeRates({
                sol: data.solana.usd,
                xrp: data.ripple.usd,
                btc: data.bitcoin.usd // Added BTC
            });
        } catch (error) {
            console.error('Failed to fetch exchange rates:', error);
        }
    };

    // Calculate price in selected cryptocurrency
    const calculatePriceInCrypto = (priceInUSD, crypto) => {
        switch (crypto) {
            case 'SOL':
                return priceInUSD / exchangeRates.sol;
            case 'XRP':
                return priceInUSD / exchangeRates.xrp;
            case 'BTC':
                return priceInUSD / exchangeRates.btc; // Added BTC
            default:
                return priceInUSD; // Default to USDT
        }
    };

    // Fetch exchange rates on component mount
    useEffect(() => {
        fetchExchangeRates();
    }, [selectedCrypto]);

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);
    }, [navbarHeight, section1Height]);

    useEffect(() => {
        switch (selectedPaymentPlan) {
            case 'monthly':
                setPriceInUSDT(50);
                setOldPrice(129);
                break;
            case '6-month':
                setPriceInUSDT(150);
                setOldPrice(289);
                break;
            case 'lifetime':
            default:
                setPriceInUSDT(300);
                setOldPrice(499);
                break;
        }
    }, [selectedPaymentPlan]);

    const handleToggle = (paymentPlan) => {
        setSelectedPaymentPlan(paymentPlan);
    };

    const handleCryptoChange = (crypto) => {
        setSelectedCrypto(crypto);
    };

    const handleCopyToClipboard = () => {
        const addressText = document.getElementById('address').innerText;
        navigator.clipboard.writeText(addressText).then(() => {
            alert('Address copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const paymentInfo = {
        USDT: {
            address: 'TEPJARNvzP9yCWfDhpkNeXm8dUaGg6P8f9',
            qrCode: '../.././assets/images/services/vip/USDT.JPG',
            text: 'SEND USDT ONLY',
            network: 'NETWORK: TRC20'
        },
        SOL: {
            address: 'BrxzC4N2JdH9sykdCcRCTeNrZc8qqkNBnZziixpMej1C',
            qrCode: '../.././assets/images/services/vip/sol-full.jpg',
            text: 'SEND SOL ONLY',
            network: 'NETWORK: SOLANA'
        },
        BTC: {
            address: '1D64Qe6dEpf3cHidjNTquJAQwfoCb4FWuJ',
            qrCode: '../.././assets/images/services/vip/btc-full.jpg',
            text: 'SEND BTC ONLY',
            network: 'NETWORK: BITCOIN'
        },
        XRP: {
            address: 'rDbf8aaRm453vAXt9TGYTH4te2cHXJYgWQ',
            qrCode: '../.././assets/images/services/vip/xrp-full.jpg',
            text: 'SEND XRP ONLY',
            network: 'NETWORK: RIPPLE',
            tag: xrpTag
        }
    };

    return (
        <div className="main-content">
            <img
                src='../.././assets/images/backgrounds/bg1.png'
                className='payment-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className="payment-main">
                <div className="payment-section1" ref={section1Ref}>
                    <h1 className="payment-title">VIP MEMBERSHIP</h1>
                    <div className="payment-options">
                        <div className="toggle-group">
                            <button
                                className={`toggle-btn ${selectedPaymentPlan === 'monthly' ? 'toggled' : ''}`}
                                onClick={() => handleToggle('monthly')}
                            >
                                <div className="thumb"></div>
                            </button>
                            <label>Monthly</label>
                        </div>
                        <div className="toggle-group">
                            <button
                                className={`toggle-btn ${selectedPaymentPlan === '6-month' ? 'toggled' : ''}`}
                                onClick={() => handleToggle('6-month')}
                            >
                                <div className="thumb"></div>
                            </button>
                            <label>6 Months</label>
                        </div>
                        <div className="toggle-group">
                            <button
                                className={`toggle-btn ${selectedPaymentPlan === 'lifetime' ? 'toggled' : ''}`}
                                onClick={() => handleToggle('lifetime')}
                            >
                                <div className="thumb"></div>
                            </button>
                            <label>Lifetime</label>
                        </div>
                    </div>

                    <div className="payment-details">
                        <div className="payment-details-glass">
                            <h1 className="membership-name">{selectedPaymentPlan.toUpperCase()} VIP</h1>
                            <h2 className="membership-price" style={{ color: '#fff', marginBottom: '3vh' }}>
                                {selectedCrypto === 'USDT'
                                    ? `$${priceInUSDT}`
                                    : `${calculatePriceInCrypto(priceInUSDT, selectedCrypto).toFixed(4)} ${selectedCrypto}`
                                }
                            </h2>
                            <img src={paymentInfo[selectedCrypto].qrCode} alt={`${selectedCrypto} QR Code`} className="payment-qr" />
                            <div className="payment-crypto-selection">
                                <button
                                    className={`payment-crypto-btn ${selectedCrypto === 'USDT' ? 'active' : ''}`}
                                    onClick={() => handleCryptoChange('USDT')}
                                >
                                    USDT
                                </button>
                                <button
                                    className={`payment-crypto-btn ${selectedCrypto === 'SOL' ? 'active' : ''}`}
                                    onClick={() => handleCryptoChange('SOL')}
                                >
                                    SOL
                                </button>
                                <button
                                    className={`payment-crypto-btn ${selectedCrypto === 'XRP' ? 'active' : ''}`}
                                    onClick={() => handleCryptoChange('XRP')}
                                >
                                    XRP
                                </button>
                                <button
                                    className={`payment-crypto-btn ${selectedCrypto === 'BTC' ? 'active' : ''}`}
                                    onClick={() => handleCryptoChange('BTC')}
                                >
                                    BTC
                                </button>
                            </div>
                            <div className="payment-details-address">
                                <p id="address">{paymentInfo[selectedCrypto].address}</p>
                                {/* Conditionally render the tag if it exists */}
                                {paymentInfo[selectedCrypto].tag && (
                                    <p id="tag">Memo/Tag: {paymentInfo[selectedCrypto].tag}</p>
                                )}
                            </div>
                            <button onClick={handleCopyToClipboard} className="payment-copy-button">
                                <img src={copy} alt="copy icon" className="payment-copy-icon" />COPY
                            </button>
                            <div className="payment-subheader">
                                <h2>{paymentInfo[selectedCrypto].text}</h2>
                                <div className="payment-divider"></div>
                                <h2>{paymentInfo[selectedCrypto].network}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="payment-section2">
                    <h1>REDEEM YOUR MEMBERSHIP</h1>
                    <h2>SEND A SCREENSHOT OF YOUR PAYMENT</h2>
                    <a href="https://t.me/cryptoxnftsignals" target="blank"><button className="button-dark">SEND</button></a>
                </div>
            </div>
            <div className="footer"><Footer /></div>
        </div>
    );
};

export default Payment2;