import React, { useEffect, useState, useRef } from "react";
import './vip.css'
import Footer from '../../universal/footer'
import '../../universal/buttons.css'
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";
import VipCard from "../../universal/vipCard";
import { Link } from "react-router-dom";
import TimerLead from "../../universal/timerLeaderboard";
//import Snowfall from "react-snowfall";

const VIP = ({ navbarHeight }) => {

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
    const section1Height = useDelayedHeightCalculation(section1Ref, 100);

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);
    }, [navbarHeight, section1Height]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 769);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="main-content">
            <img src='.././assets/images/backgrounds/bg1.png'
                className='vip-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background" />

            <div className="vip-section1" ref={section1Ref}>
                <div className="santa-div">
                    <h1 className="vip-section1-header1">
                        {isMobile ? "CryptoxNFT VIP" : "Unlock Your VIP Access to CryptoxNFT"}
                    </h1>
                </div>
                <h2 className="vip-section1-subheader">
                    {isMobile ? "Maximize your success." : "Gain exclusive insights and predictions to maximize your crypto success."}
                </h2>
                {/*} <h1 className="vip-section1-header1" style={{marginTop: isMobile ? "3vh" : ""}}>
                    {isMobile ? "80% SALE" : "MASSIVE 80% SALE"}
                </h1>*/}
                {/*<div className="vip-section1-timer" style={{marginTop: isMobile ? "-2vh" : "-2vh"}}><TimerLead/></div>*/}
                <div className="vip-section1-vipcard"><VipCard /></div>
                <div className="vip-images">
                    <img src=".././assets/images/services/vip/pnl1.png" alt="pnl1" />
                    <img src=".././assets/images/services/vip/pnl2.png" alt="pnl2" className="vip-image-middle" />
                    <img src=".././assets/images/services/vip/pnl3.png" alt="pnl3" />
                </div>
            </div>

            <div className="vip-section2">
                <h1>NOT SURE WHAT TO EXPECT?</h1>
                <h2>TAKE A LOOK INSIDE OUR VIP TELEGRAM GROUP</h2>
                <div className="vip-section2-vids">
                    <video src=".././assets/images/services/vip/vid1.MOV" loop={true} muted={true} autoPlay={true} playsInline={true} webkit-playsinline="true" />
                    <video src=".././assets/images/services/vip/vid2.MOV" loop={true} muted={true} autoPlay={true} playsInline={true} webkit-playsinline="true" />
                </div>
                <Link><button className="button-dark">RESULTS</button></Link>
            </div>

            <div className="footer"><Footer /></div>
        </div>
    )
}

export default VIP;